import React from 'react'
import { images } from 'config/images'

export const SoftwareDevComProps = {
  ppcbannerProps: {
    heading: (
      <>
        Trusted and reliable{' '}
        <span className="highlight-head">Software Development Company</span>
      </>
    ),
    para: (
      <>
        Gain competitive edge using our software development services. Our 900+
        happy clients have observed increased revenue,{' '}
        <span className="color-through">streamlined processes and gained</span>{' '}
        business agility with our services.
      </>
    ),
    formCTA: 'Get a Free Consultation!',
  },
  buildApplicationProps: {
    buildApplicationHeading: (
      <>
        An extended team which delivers{' '}
        <span className="highlight-head">software solutions</span> faster and
        better
      </>
    ),
    buildAppPara: (
      <>
        Build your business by focusing on what you do best. Leave the rest to
        us. Let's be that team that helps you in{' '}
        <span className="color-through">scaling your operations at ease.</span>
      </>
    ),
    buildVid: images.demoVideo,
    posterAlt: 'Concepting',
    lightboxImgVid: 'Rsk2-XQhCAk',
    videoNote: 'Play video to see how we work',
  },
  clientLogoPrpos: {
    clientLogoHeading: (
      <>
        Trusted by <span className="color-through">900+ happy clients</span>{' '}
        including these Fortune companies
      </>
    ),
    clientLogos: [
      {
        clientLogoLink: 'https://www.redbull.com',
        clientLogosrc: images.redbull,
        clientLogosrcg: images.redbullg,
        clientLogoAlt: 'Redbull',
      },
      {
        clientLogoLink: 'https://www.pepsico.com/',
        clientLogosrc: images.pepsico,
        clientLogosrcg: images.pepsicog,
        clientLogoAlt: 'Pepsico',
      },
      {
        clientLogoLink: 'https://www.apa.org/',
        clientLogosrc: images.apag,
        clientLogosrcg: images.apa,
        clientLogoAlt: 'Americal Psychological Association',
      },
      {
        clientLogoLink: 'https://www.santander.co.uk/',
        clientLogosrc: images.santanderg,
        clientLogosrcg: images.santander,
        clientLogoAlt: 'Santander',
      },
      {
        clientLogoLink: 'https://www.bankofamerica.com/',
        clientLogosrc: images.boag,
        clientLogosrcg: images.boa,
        clientLogoAlt: 'Bank of America',
      },
      {
        clientLogoLink: 'https://www.cisco.com/',
        clientLogosrc: images.ciscog,
        clientLogosrcg: images.cisco,
        clientLogoAlt: 'Cisco',
      },
      {
        clientLogoLink: 'https://www.experian.com/',
        clientLogosrc: images.experiang,
        clientLogosrcg: images.experian,
        clientLogoAlt: 'Experian',
      },
      {
        clientLogoLink: 'https://www.fidelity.com/',
        clientLogosrc: images.fidelityg,
        clientLogosrcg: images.fidelity,
        clientLogoAlt: 'Fidelity',
      },
      {
        clientLogoLink: 'https://www.cameo.com/',
        clientLogosrc: images.cameog,
        clientLogosrcg: images.cameo,
        clientLogoAlt: 'Cameo',
      },
      {
        clientLogoLink: 'https://www.hsbc.com/',
        clientLogosrcg: images.hsbc,
        clientLogosrc: images.hsbcg,
        clientLogoAlt: 'HSBC',
      },
      {
        clientLogoLink: 'https://www.jpmorganchase.com/',
        clientLogosrc: images.jpmg,
        clientLogosrcg: images.jpm,
        clientLogoAlt: 'JP Morgan',
      },
      {
        clientLogoLink: 'https://www.larvol.com/',
        clientLogosrc: images.larvolg,
        clientLogosrcg: images.larvol,
        clientLogoAlt: 'Larvol',
      },
      {
        clientLogoLink: 'https://www.natwestgroup.com',
        clientLogosrc: images.netwestg,
        clientLogosrcg: images.netwest,
        clientLogoAlt: 'NetWest',
      },
      {
        clientLogoLink: 'https://solvpath.com/',
        clientLogosrc: images.solvepathg,
        clientLogosrcg: images.solvepath,
        clientLogoAlt: 'Solve Path',
      },
      {
        clientLogoLink: 'https://nextbigideaclub.com/',
        clientLogosrc: images.nbicg,
        clientLogosrcg: images.nbic,
        clientLogoAlt: 'NBIC',
      },
      {
        clientLogoLink: 'https://www.news.co.uk/',
        clientLogosrc: images.newsukg,
        clientLogosrcg: images.newsuk,
        clientLogoAlt: 'News UK',
      },
    ],
    clientCount: '900+',
    clienttitle: 'Happy Clients',
  },
  ppcServiceProps: {
    heading: (
      <>
        Key <span className="highlight-head">Software Development</span>{' '}
        Offerings
      </>
    ),
    serviceContents: [
      {
        id: '1',
        serviceHeading: 'Custom Software Development Services',
        serviceParagraph: (
          <>
            Off the shelf software can be a good{' '}
            <strong>short term solution.</strong> The problem? It doesn’t scale
            with your business and your needs. You need software that fits your
            processes and workflows to outcompete others.
          </>
        ),
        serviceIcon: images.customSoftwareIcon,
        serviceAlt: 'Automation',
        serviceClass: 'very-light-pink',
      },
      {
        id: '2',
        serviceHeading: 'Web application development services',
        serviceParagraph: (
          <>
            Our web application development service includes everything from a
            simple CMS enabled website to developing{' '}
            <strong>powerful progressive web apps,</strong> custom business
            applications, e-commerce portals, and SaaS applications.
          </>
        ),
        serviceIcon: images.softwareTesting2,
        serviceAlt: 'Mobile',
        serviceClass: 'pale',
      },
      {
        id: '3',
        serviceHeading: 'Mobile app development services',
        serviceParagraph: (
          <>
            Building the future requires the agility of action and attitude. Our
            extended teams provide{' '}
            <strong>scale, experience, acceleration,</strong> and a whole lot of
            talent - to help you rapidly create modern apps that engage users.
          </>
        ),
        serviceIcon: images.enterprise,
        serviceAlt: 'Manual',
        serviceClass: 'pale-grey',
      },
      {
        id: '4',
        serviceHeading: 'Software product development services',
        serviceParagraph: (
          <>
            From building a prototype to{' '}
            <strong>validation, iteration and optimization </strong>
            we do it all for you to articulate your vision into a live product
          </>
        ),
        serviceIcon: images.webAppDevIcon,
        serviceAlt: 'Software',
        serviceClass: 'ice-blue',
      },
      {
        id: '5',
        serviceHeading: 'QA and Testing services',
        serviceParagraph: (
          <>
            Confidently deliver software faster with a{' '}
            <strong>talented team of testers</strong> and robust QA processes.
          </>
        ),
        serviceIcon: images.dedicatedSoftware,
        serviceAlt: 'DevOps',
        serviceClass: 'ice',
      },
      {
        id: '6',
        serviceHeading: 'API integration services',
        serviceParagraph: (
          <>
            API integration services are used to get systems and apps to work
            together. This also helps in{' '}
            <strong>reducing development time and costs</strong> by integrating
            third-party API platforms & systems
          </>
        ),
        serviceIcon: images.productDevelopment,
        serviceAlt: 'Performance',
        serviceClass: 'pale-lavender',
      },
    ],
  },
  workingBenefitsProps: {
    workingBenefitsHeading: (
      <>
        <span className="highlight-head">Benefits</span> of working with <br />
        Simform
      </>
    ),
    workingBenefitsProps: [
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Strategy and consulting to set the right goals',
        workingBenefitsContent: (
          <>
            We co-create apps by understanding your business needs first. We
            identify processes that can be streamlined and build a{' '}
            <strong>tech delivery roadmap</strong> to align with your goals.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlue,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Product development approach to mobile apps',
        workingBenefitsContent: (
          <>
            Our team takes your ideas and turns them into apps with{' '}
            <strong>high ROI and retention rates</strong>. Our UX driven
            approach with user research, usability testing, and design thinking
            ensure an engaging app.
          </>
        ),
        workingBenefitsPatern: images.processFlowLightBlueDown,
      },
      {
        workingBenefitsClass: 'very-light-blue-two',
        workingBenefitsTitle: 'Incorporate Security Best Practices',
        workingBenefitsContent: (
          <>
            Mobile app <strong>testing best practice</strong> reduces the
            security risks, tests potential vulnerabilities and incorporates
            data encryption. This ensures that an application, APIs and its data
            is secure from potential attacks.
          </>
        ),
      },
    ],
  },
  caseStudyProps: {
    caseStudyheading: (
      <>
        Recent <span className="highlight-head">Case studies</span>
      </>
    ),
    caseStudiesContents: [
      {
        caseStudiesTitle:
          'Building EV charging platform to revolutionize the way we power',
        caseStudiesParaGraph:
          '…clean and quiet on-site power to substitute or supplement the use of diesel generators for a variety of remote power needs…',
        caseStudiesClassName: 'duck-egg-blue',
        caseStudiesImgAlt: 'Mobile Mockup',
        caseURL: '/case-studies/freewire-scalable-vehicle-charging/',
      },
      {
        caseStudiesTitle:
          'Improving the online web experience for International Hockey Federation (FIH)',
        caseStudiesParaGraph:
          '…increased user engagement, usability, discoverability, while providing modern user experience (UX)…',
        caseStudiesClassName: 'light-peach',
        caseStudiesImgAlt: 'Safari',
        caseURL: '/case-studies/fih/',
      },
      {
        caseStudiesTitle: 'Helping luxury shoe brand go digital',
        caseStudiesParaGraph:
          "…take customized orders by applying various combinations of fabrics, motifs, and monograms based on the customer's preferences…",
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Ipad',
        caseURL: '/case-studies/stubbs-woottons/',
      },
      {
        caseStudiesTitle: 'Helping retailers modernize shopping experience',
        caseStudiesParaGraph:
          '…solving the multi-billion dollar problem of faster checkouttimes, customer loyalty programs, and deeper analytics...',
        caseStudiesClassName: 'light-grey-two',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/swift-shopper-retail-checkout/',
      },
      {
        caseStudiesTitle: 'Shortcut to get featured on Redbull website',
        caseStudiesParaGraph:
          '...custom scalable solution that can easily accommodate their event engagement needs…',
        caseStudiesClassName: 'light-sky-blue',
        caseStudiesImgAlt: 'Device',
        caseURL: '/case-studies/redbull-photobooth/',
      },
    ],
  },
  customReviewSliderProps: {
    heading: (
      <>
        What <span className="highlight-head">customers say</span> about our
        Software development company
      </>
    ),
    textTestimonialProps: [
      {
        clientName: 'Jansen Myburgh',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          "Decision was made easier after reviewing their employee's feedback",
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087790',
      },
      {
        clientName: 'Danielle Beben',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'The communication line was always open and they are very approachable',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346088120',
      },
      {
        clientName: 'Jawann Swislow',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They took care of design and even architecture side as well',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087960',
      },
      {
        clientName: 'Justin Wollman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform has variety of engineers to meet our current and future demands',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087717',
      },
      {
        clientName: 'Olivia Butters',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'We needed someone who could grasp our vision',
        rating: '40%',
        videoURL: 'https://player.vimeo.com/video/346087638',
      },
      {
        clientName: 'Ritwik Pavan',
        clientDesignation: 'Product Designer',
        clientTestimonial: 'I have been thrilled to have such a dedicated team',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087564',
      },
      {
        clientName: 'Jeff Petreman',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'Simform is real asset to the TransAction Logistics team.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/346087854',
      },
      {
        clientName: 'Andy Gibson',
        clientDesignation: 'Product Designer',
        clientTestimonial:
          'They’ve always been able to come up with solution through technology.',
        rating: '40%',
        videoURL: '//player.vimeo.com/video/368290760',
      },
    ],
  },
  featureResourceProps: {
    heading: 'Featured Resources',
    featureContents: [
      {
        blogLink:
          'https://www.simform.com/build-ecommerce-web-application-node-js/',
        featurecardTitle:
          'How to Build an eCommerce Web Application using Node.js?',
      },
      {
        blogLink: 'https://www.simform.com/what-is-tdd/',
        featurecardTitle: 'What is TDD?',
      },
      {
        blogLink: 'https://www.simform.com/test-automation-strategy/',
        featurecardTitle: 'How to Build a Test Automation Strategy?',
      },
    ],
  },
  ppcGetTouchProps: {
    formHeading: 'Get in Touch',
    personImg: images.contactMichaelCullinan,
    personImg2x: images.contactMichaelCullinan2x,
    personName: 'Michel Cullian',
    personDesignation:
      'Creating UX driven software products to build a growth engine for your organization.',
    buttonName: 'Call us now',
    buttonURL: '/contact/',
  },
}
